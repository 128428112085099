import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import classnames from 'classnames'
import Theme from '@refera/ui-core'
import { MoneyInput, PercentInput } from '@refera/ui-web'

import useRolePermission from '_hooks/use-role-permission'

import useStyles from './styles'
import { round } from '_/views/finance/utils/functions'

const PriceSection = ({ budget, handleInputChange, disabled }) => {
  const styles = useStyles()
  const { isAdmin } = useRolePermission()
  const [inputValue, setInputValue] = useState()
  const [inputPercentage, setInputPercentage] = useState()

  const itemsList = useMemo(() => {
    const adminOnlyItems = [
      {
        label: 'Total prestador',
        value: budget.totalPriceTradesman,
        name: 'totalPriceTradesman',
        type: 'read',
        style: styles.value,
        totalReference: null,
      },
      {
        label: 'Tarifa da intermediária',
        value: budget.agencyCompletionPercentage,
        name: 'agencyCompletionPercentage',
        type: 'input',
        style: null,
        totalReference: budget.totalPriceTradesman,
      },
      {
        label: 'Tarifa da Refera',
        value: budget.referaCompletionPercentage,
        name: 'referaCompletionPercentage',
        type: 'input',
        style: null,
        totalReference: budget.totalPriceTradesman,
      },
      {
        label: 'Receita Financeira',
        value: budget.financialIncomeValue,
        name: 'financialIncomeValue',
        type: 'read',
        style: styles.value,
        totalReference: budget.totalPriceTradesman,
      },
    ]

    const defaultItems = [
      {
        label: 'Valor bruto',
        value: budget?.totalPrice,
        name: 'totalPrice',
        type: 'read',
        style: classnames(styles.value, styles.greenValue, styles.medium),
        totalReference: null,
      },
      {
        label: 'Imposto retido',
        value: `${budget?.withholdingTax || 0}%`,
        name: 'withholdingTax',
        type: 'input',
        style: classnames(styles.value, styles.redValue),
        totalReference: budget?.totalPrice,
      },
    ]

    if (isAdmin) {
      return [...adminOnlyItems, ...defaultItems]
    }

    return defaultItems
  }, [budget, isAdmin])

  const displayCurrency = value => {
    return value
      ? value.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })
      : 'R$ 0,00'
  }

  const displayPercentage = value => {
    return value
      ? (value / 100).toLocaleString('pt-br', {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : '0,00%'
  }

  const handleMoneyInputStyle = item => {
    if (item?.name === 'withholdingTax') {
      return { color: Theme.Colors.Red.Base, fontSize: '16px' }
    }
    return { fontSize: '16px' }
  }

  const handleChangeValues = useCallback(
    (item, type) => {
      let percentageValue = 0
      let input = 0
      let percentageEvent
      let valueEvent

      if (type === 'value') {
        if (inputValue?.floatValue) {
          percentageValue = (inputValue?.floatValue / item.totalReference) * 100 || 0
          input = inputValue?.floatValue
        }

        if (
          inputValue?.item?.name === item.name &&
          input !== Number(parseFloat(budget[`${item.name}Value`])?.toFixed(2))
        ) {
          percentageEvent = { target: { name: item.name, value: round(percentageValue, 4) } }

          valueEvent = {
            target: { name: `${item.name}Value`, value: round(input, 2) || 0 },
          }
        }
      }
      if (type === 'percentage') {
        if (inputPercentage?.floatValue) {
          percentageValue = (inputPercentage?.floatValue / 100.0) * item.totalReference || 0
          input = inputPercentage?.floatValue
        }

        if (
          inputPercentage?.item?.name === item.name &&
          input !== Number(parseFloat(budget[item.name])?.toFixed(2))
        ) {
          percentageEvent = {
            target: { name: `${item.name}Value`, value: round(percentageValue, 2) },
          }

          valueEvent = {
            target: {
              name: item.name,
              value: Number(round(input, 4)) || 0,
            },
          }
        }
      }

      if (percentageEvent && valueEvent) {
        handleInputChange(percentageEvent)
        handleInputChange(valueEvent)
      }
    },
    [handleInputChange, budget, inputValue, inputPercentage]
  )

  const handleFinancialIncomeAndTotalPrice = useCallback(() => {
    const totalPrice =
      budget?.totalPriceTradesman +
      budget?.agencyCompletionPercentageValue +
      budget?.referaCompletionPercentageValue

    const newFinancialIncomePrice = round((totalPrice * budget?.originalFinancialIncome) / 100, 2)

    handleInputChange({ target: { name: 'financialIncomeValue', value: newFinancialIncomePrice } })

    const newTotalPrice = round(Number(totalPrice + newFinancialIncomePrice), 2)
    handleInputChange({ target: { name: 'totalPrice', value: newTotalPrice } })

    const newWithholdingTaxValue = round(newTotalPrice * (budget?.withholdingTax / 100.0), 2)
    handleInputChange({ target: { name: 'withholdingTaxValue', value: newWithholdingTaxValue } })
  }, [budget])

  const handleFinalPrices = useCallback(() => {
    const totalPriceServiceProvider = {
      target: {
        name: 'totalPriceServiceProvider',
        value: round(budget?.totalPriceTradesman - budget?.withholdingTaxValue, 2) || 0,
      },
    }
    handleInputChange(totalPriceServiceProvider)

    const liquidValue = {
      target: {
        name: 'liquidValue',
        value: round(budget?.totalPrice - budget?.withholdingTaxValue, 2) || 0,
      },
    }
    handleInputChange(liquidValue)
  }, [budget])

  useEffect(() => {
    handleFinancialIncomeAndTotalPrice()
  }, [
    budget?.totalPriceTradesman,
    budget?.agencyCompletionPercentageValue,
    budget?.referaCompletionPercentageValue,
  ])

  useEffect(() => {
    handleFinalPrices()
  }, [budget?.totalPrice, budget?.withholdingTaxValue, budget?.financialIncomeValue])

  return (
    <Grid className={styles.container}>
      <Grid className={styles.itemsContainer}>
        {itemsList?.map(item => (
          <Grid className={styles.item} key={item.label}>
            <Typography className={styles.label}>{item?.label}</Typography>
            {item?.type === 'input' && (
              <>
                <PercentInput
                  value={displayPercentage(budget[item?.name])}
                  mask=" "
                  format="####"
                  placeholder="%"
                  allowNegative={false}
                  allowLeadingZeros={false}
                  onBlur={() => handleChangeValues(item, 'percentage')}
                  onChange={({ floatValue }) => setInputPercentage({ floatValue, item })}
                  style={{ fontSize: '16px' }}
                  disabled={disabled}
                />
                <MoneyInput
                  value={displayCurrency(budget[`${item?.name}Value`])}
                  style={handleMoneyInputStyle(item)}
                  placeholder="R$ "
                  allowNegative={false}
                  allowLeadingZeros={false}
                  onBlur={() => handleChangeValues(item, 'value')}
                  onChange={({ floatValue }) => setInputValue({ floatValue, item })}
                  disabled={disabled}
                />
              </>
            )}
            {item?.type === 'read' && (
              <>
                <Grid />
                <Typography className={item?.style}>
                  {displayCurrency(budget[item?.name])}
                </Typography>
              </>
            )}
          </Grid>
        ))}
      </Grid>
      <Grid className={styles.totalWrapper}>
        {isAdmin && (
          <Grid>
            <Typography className={styles.totalLabel}>Valor final do prestador</Typography>
            <Typography className={classnames(styles.blackValue, styles.medium)}>
              {displayCurrency(budget?.totalPriceServiceProvider)}
            </Typography>
          </Grid>
        )}
        <Grid className={styles.totalField}>
          <Typography className={styles.totalLabel}>Valor líquido</Typography>
          <Typography className={classnames(styles.blueValue, styles.medium)}>
            {displayCurrency(budget?.liquidValue)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PriceSection
