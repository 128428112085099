import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing, palette }) => ({
  header: {
    // minHeight: '160px',
    height: '158px',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    boxShadow: `${spacing(0, 4, 4)} rgba(0, 0, 0, 0.25)`,
    padding: spacing(16, 24),
    // display: 'grid',
    display: 'flex',
    flexWrap: 'wrap',
    // gridTemplateColumns: `min-content min-content auto ${spacing(150)} ${spacing(120)} auto auto`,
    gap: spacing(20),
    alignItems: 'center',
    zIndex: 1,
  },
  buttons: {
    justifySelf: 'flex-end',
    marginLeft: -40,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    marginTop: -60,
  },
  chip: {
    width: 'fit-content',
    color: props => props.isCancelled && palette.gray[32],
    borderColor: props => props.isCancelled && palette.gray[32],
  },
  chipId: {
    width: 'fit-content',
    color: Theme.Colors.Grayscale.SixtyFour,
    backgroundColor: Theme.Colors.Grayscale.Twelve,
    borderRadius: spacing(24),
    height: spacing(24),
    textTransform: 'none',
    fontFamily: ['Roboto', 'sans-serif'],
    padding: '4px 8px',
    fontSize: '14px',
    lineHeight: '112.5%',
  },

  select: {
    marginBottom: spacing(16),
    minWidth: spacing(120),
    maxWidth: spacing(200),
  },
  button: {
    padding: spacing(6),
  },
  payerDataButton: {
    minWidth: spacing(100),
    padding: spacing(6),
    marginRight: spacing(16),
  },
  ownerDataButton: {
    minWidth: spacing(200),
  },
  buttonIcon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: spacing(8),
  },
  cancelButton: {
    height: spacing(32),
    padding: spacing(0, 10),
  },
  text: {
    color: props => props.isCancelled && palette.gray[32],
  },
  category: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    alignItems: 'center',
    gap: spacing(8),
  },
  scroll: {
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: '0 8px 8px 0',

    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#E3E3E3',
      borderRadius: '20px',
      margin: '30px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray',
      borderRadius: '20px',
      border: '3px solid gray',
    },
  },
}))
