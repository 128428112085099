import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, breakpoints, palette }) => ({
  page: {
    height: `calc(100vh - ${spacing(48)})`,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  container: {
    padding: spacing(24, 32),
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: spacing(32),
    overflow: 'auto',

    [breakpoints.down('md')]: {
      maxWidth: '100vw',
    },

    '&::-webkit-scrollbar': {
      width: '6px',
      height: 0,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '100vh',
      backgroundColor: palette.grey[300],
      margin: spacing(5),
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: palette.grey[300],
      borderRadius: '100vh',
      border: `3px solid grey`,
    },
  },
  headerTitle: {
    marginTop: spacing(48),
  },
  content: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: spacing(16),
    width: '100%',
    flexDirection: 'column',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    gap: spacing(8),
    marginTop: spacing(16),
    marginBottom: spacing(8),
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: spacing(4),
    minWidth: spacing(150),
  },
  descriptionSection: {
    fontSize: '14px',
  },
  radioData: {
    gap: spacing(8),
    margin: 0,
  },
  dialog: {
    position: 'absolute',
    height: 'auto',
  },
  dialogTitle: {
    textAlign: 'justify',
    color: '#4169e1',
  },
  text: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '500',
    color: palette.gray[100],
  },
  selectPlaceholder: {
    fontSize: '16px',
    marginTop: '17px',
  },
  dialogAction: {
    justifyContent: 'right',
    margin: '20px',
  },
  required: {
    color: '#C90000',
    fontSize: '12px',
  },
  selectGrid: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
    lineHeight: '20px',
    rowGap: 8,
  },
  icon: {
    height: spacing(18),
    width: spacing(18),
    marginTop: spacing(-2),
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: spacing(20),
    padding: spacing(40),
  },
  actionsModal: {
    padding: spacing(20),
    gap: spacing(12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
