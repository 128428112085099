import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import MUIButton from '@mui/material/Button'
import Svg from '_components/svg'
import moment from 'moment'
import Textfield from '_/components/textfield'
import { WhatsApp } from '@material-ui/icons'
import { Preview } from '@refera/ui-web'
import { Attachments } from '_/components/refera-ui'
import useIsGenericParamActive from '_/hooks/use-is-generic-param-active'

import { currentServiceOrderSelector } from '_/modules/service-orders/selectors'
import { GENERIC_PARAMETERS, STEP_STATUS } from '_/utils/constants/service-order'
import { formatAddress, phoneNumberMaskServiceOrder } from '_/utils/helpers'
import { DeadlineTimer } from '_/components/service-order'

import DocumentIcon from '_assets/icons/ic-document-text.svg'
import LocationIcon from '_assets/icons/ic-location.svg'
import PhoneIcon from '_assets/icons/ic-phone.svg'
import useStyles from './styles'

const defaultPreviewState = {
  visible: false,
  selectedIndex: undefined,
}

export function ServiceOrderDetailsViews() {
  const serviceOrder = useSelector(currentServiceOrderSelector)

  const [previewState, setPreviewState] = useState(defaultPreviewState)
  const property = serviceOrder?.property

  const styles = useStyles()

  const shouldDisplayContactCard = serviceOrder.stepStatus !== 'waiting_service_order_acceptance'

  const attachments = useMemo(() => {
    const files = serviceOrder
      ?.toJS()
      .attachments.filter(file => file.fileType === 'pictures_order')
      .map(file => ({ file: file.file, id: file.id }))

    const media = files.filter(link => !link.file.includes('.pdf?'))
    const pdf = files.filter(link => link.file.includes('.pdf?'))

    return { media: [...media], pdf: [...pdf] }
  }, [serviceOrder?.attachments])

  const isDelayedActive = useIsGenericParamActive({
    name: GENERIC_PARAMETERS.DELAYED,
  })

  const PreviewComponent = useMemo(
    () => (
      <Preview
        open={previewState.visible}
        onClose={() => setPreviewState(defaultPreviewState)}
        selectedIndex={previewState.selectedIndex}
        images={attachments?.media.map((item, index) =>
          item.id
            ? {
                id: item.id,
                url: item.file,
              }
            : {
                id: index,
                url: URL.createObjectURL(item),
              }
        )}
      />
    ),
    [previewState, attachments]
  )

  return (
    <>
      {!!attachments?.media && PreviewComponent}
      <Grid className={styles.orderDetails}>
        <Grid className={styles.card}>
          <Grid className={styles.iconContent}>
            <Svg icon={DocumentIcon} className={styles.icon} />
          </Grid>
          <Grid className={styles.infoCard}>
            <Typography component="h2" className={styles.cardTitle}>
              Dados do chamado
            </Typography>
            <Typography component="h2" className={[styles.cardSubTitle, styles.textBold]}>
              ID {serviceOrder?.id}
            </Typography>
            <Typography className={styles.serviceType}>
              {serviceOrder?.getIn(['services', 'name'])}
            </Typography>

            <Grid className={styles.infoCardDate}>
              {serviceOrder?.createdAt && (
                <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography className={styles.cardText}>Recebido em</Typography>
                  <Typography display="block">
                    {moment(serviceOrder?.createdAt).format(`DD/MM/YYYY à[s] HH[h]mm`)}
                  </Typography>
                </Grid>
              )}
              {serviceOrder?.visitSchedule && (
                <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography className={styles.cardText}>Visita para orçamento em</Typography>
                  <Typography display="block">
                    {moment(serviceOrder?.visitSchedule).format(`DD/MM/YYYY à[s] HH[h]mm`)}
                  </Typography>
                </Grid>
              )}
              {(serviceOrder?.stepStatus === STEP_STATUS.WAITING_SERVICE_ORDER_ACCEPTANCE ||
                isDelayedActive) && (
                <Grid
                  style={{ display: 'flex', flexDirection: 'column', marginTop: '-16px' }}
                  className={styles.infoCard}
                >
                  <DeadlineTimer />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid className={styles.card}>
          <Grid className={styles.iconContent}>
            <Svg icon={LocationIcon} className={styles.icon} />
          </Grid>
          <Grid className={styles.infoCard}>
            <Typography component="h2" className={styles.cardTitle}>
              Endereço do imóvel
            </Typography>
            {property?.size && (
              <Typography className={styles.cardSubTitle}>
                {formatAddress(property, true, true, true)}
              </Typography>
            )}
          </Grid>
        </Grid>

        {shouldDisplayContactCard && (
          <Grid className={styles.card}>
            <Grid className={styles.iconContent}>
              <Svg icon={PhoneIcon} className={styles.icon} />
            </Grid>
            <Grid className={styles.infoCard}>
              <Typography component="h2" className={styles.cardTitle}>
                Contato
              </Typography>
              <Typography className={styles.cardSubTitle}>{serviceOrder?.contactName}</Typography>
              {!!serviceOrder?.contactNumber && (
                <MUIButton
                  component="a"
                  endIcon={<WhatsApp className={styles.WhatsAppIcon} />}
                  href={`https://api.whatsapp.com/send?phone=${serviceOrder?.contactNumber
                    ?.toString()
                    .slice(1)}`}
                  underline="none"
                  target="_blank"
                  className={styles.iconButton}
                  variant="text"
                >
                  <Typography className={styles.cardSubTitle}>
                    {phoneNumberMaskServiceOrder(serviceOrder?.contactNumber)}
                  </Typography>
                </MUIButton>
              )}
              {!!serviceOrder?.firstContactSecondary && (
                <MUIButton
                  component="a"
                  target="_blank"
                  endIcon={<WhatsApp className={styles.WhatsAppIcon} />}
                  href={`https://api.whatsapp.com/send?phone=${serviceOrder?.firstContactSecondary
                    ?.toString()
                    .slice(1)}`}
                  underline="none"
                  className={styles.iconButton}
                  variant="text"
                >
                  <Typography className={styles.cardSubTitle}>
                    {phoneNumberMaskServiceOrder(serviceOrder?.firstContactSecondary)}
                  </Typography>
                </MUIButton>
              )}
              {!!serviceOrder?.secondContactSecondary && (
                <MUIButton
                  component="a"
                  target="_blank"
                  endIcon={<WhatsApp className={styles.WhatsAppIcon} />}
                  href={`https://api.whatsapp.com/send?phone=${serviceOrder?.secondContactSecondary
                    ?.toString()
                    .slice(1)}`}
                  underline="none"
                  className={styles.iconButton}
                  variant="text"
                >
                  <Typography className={styles.cardSubTitle}>
                    {phoneNumberMaskServiceOrder(serviceOrder?.secondContactSecondary)}
                  </Typography>
                </MUIButton>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid className={styles.content}>
        <Typography className={styles.cardTitle}>Descrição do chamado</Typography>
        <Grid className={styles.card}>
          <Textfield
            value={serviceOrder?.description}
            multiline
            InputProps={{
              disableUnderline: true,
              className: styles.textField,
            }}
            disabled
          />
        </Grid>
      </Grid>

      <Grid className={styles.orderAttachmentsContainer}>
        <Typography className={styles.cardTitle}>Anexos</Typography>
        <Grid className={styles.attachments}>
          <Attachments
            label="Imagens e vídeos"
            downloadable
            readOnly
            files={attachments?.media || []}
            accept={{
              'image/jpeg': [
                '.jpeg',
                '.png',
                '.jpg',
                '.bmp',
                '.webp',
                '.mkv',
                '.mp4',
                '.mov',
                '.avi',
                '.m4v',
                '.mpg',
                '.mpeg',
                '.wmv',
                '.webm',
              ],
            }}
            onItemClick={index => {
              setPreviewState(() => ({
                visible: true,
                selectedIndex: index,
              }))
            }}
          />
          <Attachments
            label="Arquivos"
            content="files"
            downloadable
            readOnly
            files={attachments?.pdf || []}
            multiple={false}
            accept={{
              'application/pdf': ['.pdf'],
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}
