import axios from 'axios'
import { API_URL } from '_/config/environment'
import { camelize } from '_/utils/token'
import { createFormDataDocuments } from '_utils/helpers'
import { formatPayload } from './general'

// FIXME: Requests should be made with redux
export const createServiceOrderForm = async payload => {
  const response = await axios.post(`${API_URL}/serviceorder-form/`, formatPayload(payload))

  return camelize(response.data)
}

export const updateServiceOrderForm = async (uuid, serviceID, payload) => {
  if (uuid) {
    const response = await axios.patch(
      `${API_URL}/serviceorder-form/${serviceID}/?uuid=${uuid}`,
      createFormDataDocuments(payload)
    )
    return camelize(response.data)
  }
  const response = await axios.patch(
    `${API_URL}/serviceorder-form/${serviceID}/`,
    createFormDataDocuments(payload)
  )
  return camelize(response.data)
}

export const finalizeServiceOrderForm = async (uuid, serviceID) =>
  axios.patch(`${API_URL}/serviceorder-form/${serviceID}/finalize/?uuid=${uuid}`, {})

export const getAgencyBySlug = async slug => {
  const response = await axios.get(`${API_URL}/agency-slug/${slug}/`)

  return camelize(response.data)
}

export const getServiceOrderForm = async (uuid, serviceOrderID) => {
  if (uuid) {
    const response = await axios.get(`${API_URL}/serviceorder-form/${serviceOrderID}/?uuid=${uuid}`)
    return camelize(response.data)
  }
  const response = await axios.get(`${API_URL}/serviceorder-form/${serviceOrderID}/`)
  return camelize(response.data)
}
