import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Loader, useConfirm } from '@refera/ui-web'
import { Grid } from '@material-ui/core'
import { useParams, navigate } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'

import { GET_USER, getUser } from '_modules/authentication/actions'
import { getBudget, GET_BUDGET } from '_modules/budget/actions'
import {
  getServiceOrder,
  sendExecutionProofsAttachments,
  GET_SERVICE_ORDER,
  SCHEDULE_EXECUTION,
  SEND_EXECUTION_PROOFS_ATTACHMENTS,
  SEND_INVOICE,
  DELETE_INVOICE,
  DELETE_EXECUTION_PROOFS_ATTACHMENTS,
} from '_modules/service-orders/actions'
import { getBudgetByIdSelector } from '_modules/budget/selectors'
import { serviceOrderCurrentSelector } from '_modules/service-orders/selectors'
import useFetchCall from '_hooks/use-fetch-call'
import useRolePermission from '_hooks/use-role-permission'

import ConfirmRemoveDialog from '_components/dialogs/ConfirmRemoveDialog'
import { ExecutionForm, ExecutionViewDialog, ExecutionViewHeader } from '_components/service-order'

import useStyles from './styles'
import { userSelector } from '_/modules/authentication/selectors'

const SCREEN_DIALOGS = [
  {
    subject: 'Atenção!',
    description:
      'Clique antes em "Reprovar serviço" na tela do chamado para liberar a edição destes dados.',
    type: 'warning',
  },
  {
    subject: 'Atenção!',
    description:
      'Aprove antes o orçamento em questão para ter acesso aos dados de execução dos serviços.',
    type: 'warning',
  },
  {
    subject: 'Atenção!',
    description:
      'Depois de inserido uma data de Agendamento de execução, ela não pode mais ser anulada. Insira uma nova data esperada.',
    type: 'warning',
  },
  {
    subject: 'Atenção!',
    description:
      'Para aceitar uma "Data do serviço executado", você deve inserir a data de agendamento da execução, imagens do pós-obra e a nota fiscal.',
    type: 'warning',
  },
  {
    subject: 'Registro salvo com sucesso.',
    type: 'success',
  },
  {
    subject:
      'Data de expectativa de finalização do serviço inferior a data do agendamento da execução.',
    type: 'warning',
  },
  {
    subject: 'Ocorreu um erro ao realizar a operação',
    type: 'warning',
  },
]

const ExecutionScreen = () => {
  const styles = useStyles()
  const urlParams = useParams()
  const dispatch = useDispatch()

  const { serviceOrderId, budgetId } = urlParams
  const [executionProofs, setExecutionProofs] = useState([])
  const [loading, setLoading] = useState(false)
  const [executionSent, setExecutionSent] = useState(false)
  const [sent, setSent] = useState(false)

  const serviceOrder = useSelector(serviceOrderCurrentSelector)
  const budget = useSelector(getBudgetByIdSelector(budgetId))
  const { isIntermediary } = useRolePermission()
  const user = useSelector(userSelector)

  const ableToChangeAttachment = useMemo(() => {
    return serviceOrder?.allowSendAttachmentInvoice && !isIntermediary
  }, [isIntermediary, serviceOrder])

  const [dialog, setDialog] = useState({
    isOpen: false,
    subject: undefined,
    description: undefined,
    type: undefined,
  })

  const defaultValues = useMemo(
    () => ({
      datetimeExecutionScheduled: serviceOrder?.get('datetimeExecutionScheduled') || null,
      dateAndTimeFinish: serviceOrder?.get('dateAndTimeFinish') || null,
      executedAt: serviceOrder?.get('executedAt') || null,
      datetimeFinished: serviceOrder?.get('datetimeFinished') || null,
    }),
    [serviceOrder]
  )
  const methods = useForm({ defaultValues })
  const { reset } = methods

  const handleDialog = order => {
    setDialog({
      isOpen: true,
      ...SCREEN_DIALOGS[order],
    })
  }

  const handleFetchError = useCallback(() => {
    navigate('/link-expirado')
  }, [])

  const handleSaveFieldsError = useCallback(() => {
    handleDialog(6)
  }, [])

  const handleSuccess = useCallback(() => {
    setSent(true)
    handleDialog(4)
    setExecutionSent(true)
    setLoading(false)
    dispatch(getServiceOrder(serviceOrderId))
  }, [serviceOrderId])

  const [isLoadingUser] = useFetchCall(GET_USER.ACTION)
  const [isLoadingBudget] = useFetchCall(GET_BUDGET.ACTION, () => {}, handleFetchError)
  const [isLoadingServiceOrder] = useFetchCall(GET_SERVICE_ORDER.ACTION, () => {}, handleFetchError)
  const [isLoadingScheduleExecution] = useFetchCall(
    SCHEDULE_EXECUTION.ACTION,
    handleSuccess,
    handleSaveFieldsError
  )
  const [isLoadingSendInvoices] = useFetchCall(SEND_INVOICE.ACTION, handleSuccess)
  const [isLoadingDeleteInvoices] = useFetchCall(
    DELETE_INVOICE.ACTION,
    handleSuccess,
    handleSaveFieldsError
  )
  const [isLoadingSendExecAttachments] = useFetchCall(
    SEND_EXECUTION_PROOFS_ATTACHMENTS.ACTION,
    handleSuccess,
    handleSaveFieldsError
  )
  const [isLoadingDeleteExecAttachments] = useFetchCall(
    DELETE_EXECUTION_PROOFS_ATTACHMENTS.ACTION,
    handleSuccess,
    handleSaveFieldsError
  )

  const isLoading = useMemo(
    () =>
      isLoadingUser ||
      isLoadingBudget ||
      isLoadingServiceOrder ||
      isLoadingScheduleExecution ||
      isLoadingSendInvoices ||
      isLoadingDeleteInvoices ||
      isLoadingSendExecAttachments ||
      isLoadingDeleteExecAttachments,
    [
      isLoadingUser,
      isLoadingBudget,
      isLoadingServiceOrder,
      isLoadingScheduleExecution,
      isLoadingSendInvoices,
      isLoadingDeleteInvoices,
      isLoadingSendExecAttachments,
      isLoadingDeleteExecAttachments,
    ]
  )

  const { isConfirmed } = useConfirm()

  useEffect(() => {
    if (serviceOrderId && budgetId && !budget && !isLoadingBudget) {
      dispatch(getBudget(serviceOrderId, budgetId))
    }
  }, [budget, budgetId, dispatch, isLoadingBudget, serviceOrderId])

  useEffect(() => {
    if (serviceOrderId && !serviceOrder && !isLoadingServiceOrder) {
      dispatch(getServiceOrder(serviceOrderId))
    }
  }, [isLoadingServiceOrder, serviceOrder, serviceOrderId])

  useEffect(() => {
    if (serviceOrder && budgetId && !sent) {
      resetFields()
    }
  }, [serviceOrder, budgetId, sent])

  useEffect(() => {
    if (!user?.id) {
      dispatch(getUser())
    }
  }, [user?.id])

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues])

  const handleRemoveExecutionProof = useCallback(
    async index => {
      const confirmed = await isConfirmed()
      if (confirmed) {
        setLoading(true)
        const executionProofsCopy = [...executionProofs]
        const elementToRemove = executionProofsCopy.splice(index, 1)[0]
        const payload = {
          toDelete: [elementToRemove.id],
          keepAttachments: true,
        }
        dispatch(sendExecutionProofsAttachments(serviceOrderId, payload))
          .then(() => {
            setExecutionProofs(cur => cur.filter((_, i) => index !== i))
          })
          .finally(() => {
            setLoading(false)
          })
      }
    },
    [executionProofs]
  )

  const handleAttachmentsDefault = useCallback(() => {
    if (serviceOrder?.get('attachments').size > 0) {
      const pictures = serviceOrder
        .get('attachments')
        .filter(picture => picture.get('fileType') === 'execution_proof')
        .map(picture => ({ file: picture?.get('file'), id: picture?.get('id') }))
        .toArray()
      setExecutionProofs(pictures)
    }
  }, [serviceOrder?.get('attachments')])

  const resetFields = useCallback(() => {
    if (!executionSent) {
      reset(defaultValues, {
        keepDirty: true,
      })
      handleAttachmentsDefault()
    }
  }, [defaultValues, executionSent, handleAttachmentsDefault])

  const handleDropExecutionProofs = useCallback(
    acceptedImages => {
      setLoading(true)
      const payload = {
        attachments: acceptedImages,
        keepAttachments: true,
      }
      dispatch(sendExecutionProofsAttachments(serviceOrderId, payload))
        .then(data => {
          setExecutionProofs(
            data.attachments.filter(attach => attach.fileType === 'execution_proof')
          )
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [executionProofs]
  )

  const invoices = useMemo(() => {
    if (serviceOrder?.get('attachments').size > 0) {
      return serviceOrder
        .get('attachments')
        .filter(invoice => ('invoice', 'invoice_xml').includes(invoice.get('fileType')))
        .map(invoice => ({
          file: invoice?.get('file'),
          id: invoice?.get('id'),
          fileType: invoice?.get('fileType'),
        }))
        .toArray()
    }
    return []
  }, [serviceOrder?.get('attachments')])

  return (
    <Grid className={styles.container}>
      <Loader open={loading || isLoading} hasBackdrop label="Aguarde..." />
      <ConfirmRemoveDialog message="Você deseja deletar este item?" />
      <ExecutionViewDialog
        isOpen={dialog.isOpen && !isLoading}
        type={dialog.type}
        subject={dialog.subject}
        description={dialog.description}
        onApprove={() => setDialog(old => ({ ...old, isOpen: false }))}
      />
      <ExecutionViewHeader
        executionProofs={executionProofs}
        isEditing={ableToChangeAttachment}
        invoices={invoices}
      />
      <FormProvider {...methods}>
        <ExecutionForm
          executionProofs={executionProofs}
          handleDropExecutionProofs={handleDropExecutionProofs}
          handleRemoveExecutionProof={handleRemoveExecutionProof}
          isEditing={ableToChangeAttachment}
          invoices={invoices}
        />
      </FormProvider>
    </Grid>
  )
}

export default ExecutionScreen
