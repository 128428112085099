import { SERVICE_ORDER_VALUES, PROPERTY_VALUES, STAGE_TYPES } from '_utils/constants'

// TODO: Integrate it
export const PAPER = [
  [
    {
      id: 1,
      label: 'Imobiliária',
      name: [PROPERTY_VALUES.REAL_STATE, 'name'],
      step: STAGE_TYPES.ORDER,
    },
    {
      id: 2,
      code: true,
      label: 'Código da intermediária',
      step: STAGE_TYPES.ORDER,
      hasCheckEditButton: true,
    },
    {
      id: 3,
      label: 'Dados do prestador',
      value: ['Lycas'],
      step: STAGE_TYPES.BUDGET,
    },
  ],
  [
    {
      id: 4,
      label: 'Dados do imóvel',
      isPropertyValue: true,
      step: STAGE_TYPES.ORDER,
    },
    {
      id: 5,
      label: 'Código',
      name: [SERVICE_ORDER_VALUES.ID],
      step: STAGE_TYPES.ORDER,
      isPropertyValue: true,
      hasCheckMoreButton: true,
    },
    {
      id: 6,
      label: 'Início do contrato',
      step: STAGE_TYPES.ORDER,
      isPropertyValue: true,
      contractStartDate: true,
      value: [''],
    },
    {
      id: 7,
      label: 'Diagnóstico do problema',
      value: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quisque ultricies ut bibendum interdum ultrices consequat. ',
      ],
      step: STAGE_TYPES.BUDGET,
    },
  ],
  [
    {
      id: 8,
      label: 'Solicitante',
      isUserNameValue: true,
      step: STAGE_TYPES.ORDER,
      hasEditButton: true,
    },
    {
      id: 9,
      label: 'Solução do problema',
      value: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quisque ultricies ut bibendum interdum ultrices consequat.',
      ],
      step: STAGE_TYPES.BUDGET,
    },
    {
      id: 10,
      label: 'Cliente considera emergência',
      name: [SERVICE_ORDER_VALUES.IS_EMERGENCY],
      value: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quisque ultricies ut bibendum interdum ultrices consequat.',
      ],
      step: STAGE_TYPES.ORDER,
    },
    {
      id: 11,
      label: 'Cliente possui outro orçamento',
      name: [SERVICE_ORDER_VALUES.HAS_ANOTHER_BUDGET],
      value: [''],
      step: STAGE_TYPES.ORDER,
    },
  ],
]
