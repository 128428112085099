import React, { useEffect, useMemo, useState } from 'react'
import { Document, Page, Text, View, Image } from '@react-pdf/renderer'

import ReferaLogo from '_assets/images/refera-logo.png'
import { formatDateTime } from '_views/finance/payment-advance/utils'
import PropTypes from 'prop-types'

import styles from './styles'
import { round } from '_/views/finance/utils/functions'

const BudgetPDF = ({
  budget,
  property,
  serviceOrder,
  agencyLogo,
  dateBudget = null,
  installments,
  showReferaValue,
}) => {
  const allowedExtensions = ['.jpeg', '.png', '.jpg']
  const formatterNumberOnly = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })
  const [adjustValue, setAdjustValue] = useState(0)

  const filteredPictures = useMemo(
    () =>
      budget?.budgetPictures.filter(file =>
        allowedExtensions.some(format => file?.picture?.includes(format))
      ) || [],
    [budget?.budgetPictures]
  )

  const handlePercentageToValue = (percentage, totalReference) => {
    return (percentage * totalReference) / 100.0
  }

  const totalPricePdf = useMemo(() => {
    const totalTradesman = budget?.totalPriceTradesman
    const priceAgency = handlePercentageToValue(budget?.agencyCompletionPercentage, totalTradesman)
    const priceRefera = handlePercentageToValue(budget?.referaCompletionPercentage, totalTradesman)

    return round(priceAgency + priceRefera + totalTradesman, 2)
  }, [budget])

  // Percentagem da refera
  const referaPlusAgencyPercentage = useMemo(() => {
    const agencyPercentage = (budget?.agencyCompletionPercentage || 0) / 100
    const referaPercentage = (budget?.referaCompletionPercentage || 0) / 100

    const totalPercentage = agencyPercentage + referaPercentage

    return Math.round(totalPercentage * 100)
  }, [budget])

  const tradesmanPercentage = useMemo(() => {
    const totalPercentage = referaPlusAgencyPercentage
    return 100 - totalPercentage
  }, [referaPlusAgencyPercentage])

  const itemValueCalculate = (itemValue, index = undefined) => {
    let itemPrice =
      handlePercentageToValue(budget?.referaCompletionPercentage, itemValue) +
      handlePercentageToValue(budget?.agencyCompletionPercentage, itemValue) +
      itemValue

    itemPrice = round(itemPrice, 2)
    if (index === 0 && adjustValue !== 0) itemPrice += adjustValue

    return index !== undefined ? formatterNumberOnly.format(itemPrice) : itemPrice
  }

  const handleTotalItems = () => {
    let total = 0
    budget?.budgetItems?.forEach(item => {
      total += itemValueCalculate(item?.price)
    })

    let differenceOfSumAndTotal = totalPricePdf - total
    if (Math.abs(differenceOfSumAndTotal) !== 0) {
      differenceOfSumAndTotal = round(differenceOfSumAndTotal, 2)
      setAdjustValue(differenceOfSumAndTotal)
    }
  }

  useEffect(() => {
    handleTotalItems()
  }, [budget])

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header} fixed>
          <View>
            <Image style={styles.headerLogo} src={ReferaLogo} />
          </View>
        </View>
        {agencyLogo && <Image style={styles.agencyLogo} src={agencyLogo} />}
        <View style={styles.pageSpace}>
          <Text>
            Orçamento <span style={styles.gray32}>•</span> {serviceOrder?.agency.get('name')}
          </Text>
          <View>
            <Text style={styles.headerText}>
              Chamado #{budget?.serviceOrder}, orçamento #{budget?.id}
            </Text>
          </View>
          <View style={styles.backgroundText}>
            <Text style={styles.blueText}>Dados básicos</Text>
          </View>
          <View style={styles.budgetContainer}>
            <View style={styles.descriptionText}>
              <Text style={styles.title}>Data da emissão do orçamento</Text>
              <Text style={styles.description}>{formatDateTime(dateBudget)}</Text>
            </View>
            {budget?.tradesman?.companyName && (
              <View style={styles.descriptionText}>
                <Text style={styles.title}>Prestador</Text>
                <Text style={styles.description}>
                  {`${budget.tradesman.companyName} - ${budget.tradesman.companyCnpj}`}
                </Text>
              </View>
            )}
            <View style={styles.descriptionText}>
              <Text style={styles.title}>Endereço do imóvel</Text>
              <Text style={styles.description}>{property}</Text>
            </View>
            <View style={styles.descriptionText}>
              <Text style={styles.title}>Código do imóvel</Text>
              <Text style={styles.description}>{serviceOrder?.propertyCode}</Text>
            </View>
            <View style={styles.backgroundText}>
              <Text style={styles.blueText}>Dados do serviço</Text>
            </View>
            <View style={styles.serviceData}>
              <Text style={styles.title}>Diagnóstico do problema</Text>
              <Text style={styles.description}>{budget?.diagnosis}</Text>
            </View>
            <View style={styles.serviceData}>
              <Text style={styles.title}>Solução do problema</Text>
              <Text style={styles.description}>{budget?.proposedSolution}</Text>
            </View>
            <View style={styles.backgroundText}>
              <Text style={styles.blueText}>Orçamento detalhado</Text>
            </View>
            <View style={styles.tableContainer}>
              <View style={styles.rowContainer}>
                <Text style={[styles.rowDescription, styles.gray80]}>Descrição</Text>
                <Text style={[styles.rowValues, styles.gray80]}>Valores</Text>
              </View>
              {budget?.budgetItems
                ?.sort((a, b) => a.id - b.id)
                ?.map((item, index) => (
                  <View style={styles.rowContainer} key={item.id}>
                    <View style={styles.rowDescription}>
                      <Text>{item?.description}</Text>
                    </View>
                    <View style={styles.rowValues}>
                      <Text>{itemValueCalculate(item?.price, index)}</Text>
                    </View>
                  </View>
                ))}
              <View style={styles.finalValuesContainer}>
                <View style={[styles.rowContainer, styles.totalBackground]}>
                  <Text style={[styles.rowDescriptionTotal, styles.gray80]}>Total</Text>
                  <Text style={[styles.rowValuesTotal, styles.gray100]}>
                    {formatterNumberOnly.format(totalPricePdf)}
                  </Text>
                </View>
                {budget?.withholdingTaxValue > 0 && (
                  <>
                    <View style={styles.rowContainer}>
                      <View style={styles.rowDescription}>
                        <Text>Imposto retido: </Text>
                      </View>
                      <View style={styles.rowValues}>
                        <Text>{formatterNumberOnly.format(budget?.withholdingTaxValue)}</Text>
                      </View>
                    </View>
                    <View style={[styles.rowContainer, styles.totalBackground]}>
                      <Text style={[styles.rowDescriptionTotal, styles.gray80]}>Total geral:</Text>
                      <Text style={[styles.rowValuesTotal, styles.gray100]}>
                        {formatterNumberOnly.format(totalPricePdf - budget?.withholdingTaxValue)}
                      </Text>
                    </View>
                  </>
                )}
              </View>
            </View>
            {showReferaValue && (
              <View style={styles.referaValueContainer}>
                <Text>
                  *{referaPlusAgencyPercentage}% referente à suporte ao longo de todas as etapas do
                  serviço, gestão de prestadores, orçamentação, e garantia de obra {'\n'}*
                  {tradesmanPercentage}% referente a materiais e mão de obra.
                </Text>
              </View>
            )}
            <View style={styles.titleUnbreakable}>
              <Text style={styles.installmentLabelContainer}>Parcelamento</Text>
              <View style={styles.inputInstallmentsText}>
                {installments?.length >= 1 ? (
                  <>
                    {installments?.map(installment => (
                      <View style={styles.description} key={installment}>
                        <Text>{installment.option}</Text>
                      </View>
                    ))}
                  </>
                ) : null}
              </View>
            </View>
            <View>
              <Text style={styles.installmentLabelContainer}>Garantia (meses)</Text>
              <View style={styles.inputInstallmentsText}>
                <Text>{budget?.warrantyMonths}</Text>
              </View>
            </View>
            <View style={styles.otherInfosContainer}>
              <View style={styles.otherInfosItemContainer}>
                <Text style={styles.hidden}>Prazo para iniciar execução</Text>
                {/* <View style={[styles.input, styles.otherInfosInput]}> */}
                {/* <Text style={styles.budgetItemText}> */}
                {/* {budget?.deadlineStart === null ? 0 : budget?.deadlineStart} meses */}
                {/* </Text> */}
                {/* </View> */}
              </View>
            </View>
            {/* <View style={styles.observations}>
            <Text>Observações</Text>
            <View style={[styles.input, styles.inputTextArea]}>
              <Text style={styles.description}>{budget?.observations}</Text>
            </View>
          </View> */}
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
      {Object.keys(filteredPictures).length !== 0 && (
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <Image style={styles.headerLogo} src={ReferaLogo} />
          </View>
          <View style={styles.backgroundText}>
            <Text style={styles.blueText}>Anexos</Text>
          </View>
          <View>
            {filteredPictures.map(attachment => (
              <View style={styles.attachmentContainer} key={attachment.id}>
                <Image style={styles.attachmentImage} src={attachment.picture} />
              </View>
            ))}
          </View>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
            fixed
          />
        </Page>
      )}
    </Document>
  )
}

BudgetPDF.propTypes = {
  budget: PropTypes.shape({
    budgetItems: PropTypes.arrayOf(PropTypes.shape({})),
    id: PropTypes.number,
    serviceOrder: PropTypes.number,
    acceptsInstallment: PropTypes.bool,
    proposedSolution: PropTypes.string,
    deadlineStart: PropTypes.number,
    diagnosis: PropTypes.string,
    observations: PropTypes.string,
    installments: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    acceptsWarranty: PropTypes.bool,
    warrantyMonths: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    visitHappened: PropTypes.bool,
    totalPriceTradesman: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    totalPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    referaCompletionPercentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    agencyCompletionPercentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    budgetPictures: PropTypes.arrayOf(PropTypes.shape({})),
    personPaying: PropTypes.string,
    personPayingEmail: PropTypes.string,
    personPayingWhatsapp: PropTypes.string,
    createdAt: PropTypes.string,
    financialIncome: PropTypes.number,
  }).isRequired,
  showReferaValue: PropTypes.bool.isRequired,
}

export default BudgetPDF
